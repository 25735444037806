.projectDemo {
  width: 90%;
  max-width:700px;
}

.projectLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.projectSummary {
  width: 90%;
}

.projectTechStack {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1em;
  width: 90%;
}

.projectPhoto{
  width: 200px;
  margin-bottom: 2em;
}

@media screen and (max-width: 950px) {

  .linkedSection {
    display: flex;
    flex-direction: column;
  }


}

@media screen and (max-width: 500px) {

  p, .projectSection ul {
    font-size: 12px;
  }

  .projectSection {

    padding: 1em;
  }

  .projectTitle {
    width:80%;
  }

  .techLearningList, .techStackList {
    font-size: 11px;
  }
}
