.devContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 90%;
}

.mainTechList {
    width: 65vw;
}
