

.linkedSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1em;
  align-items: center;
  border-radius: 6px;
  gap:2em;
}

.linkedSectionTitle {
    @include mainLink;
    background-color: $main-text-color;
    color: white;
}

.projectLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  gap: 0.2em;
}

.projectSection {
  align-items: center;
  background-color: white;
  border: solid 1px black;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.75em;
  width: 100%;
}

.projectSummary {
  width: 90%;
}

.projectSummaryList {
  padding-left: 20px;
}

.pojectSummaryList li {
  padding-bottom: 10px;
}

.projectTechStack {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1em;
  width: 90%;
}

.projectTitle {
  align-items: center;
  background-color: white;
  border-radius: 6px;
  box-shadow:
       0 0 0 2px $first-accent-color,
       0 0 0 4px $second-accent-color,
       0 0 0 6px $third-accent-color,
       0 0 0 8px $fourth-accent-color,
       0 0 0 10px $fifth-accent-color,
       0 0 0 12px $sixth-accent-color;
  display: flex;
  flex-direction: column;
  font-size: larger;
  justify-content: center;
  margin: 1.2em;
  padding: 0.5em;
}

.techStackList {
  margin: 0;
  padding-left: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2em;
}

.techStackTitle {
  align-self: center;
  margin: 0 0 0.5em;
}

@media screen and (max-width: 500px) {

 p {
   font-size: 12px;
 }

 .techLearningList, .techStackList {
   font-size: 11px;
 }
}

@media screen and (min-width: 951px){
  .projectSection{
    height: 100%;
    width: 92%;
  }

  .linkedSection {
    padding: 2em 1em;
    gap: 3em 1em;
  }
}

@media screen and (min-width: 1400px) {
  .linkedSection {
    padding: 3em 2em;
  }

  .projectSection {
    padding: 1em;
  }
}

