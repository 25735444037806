.discographyGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1em;
}

.discoArt {
  width: 250px;
}

.discoItem {
  align-items: center;
  border: solid 2px black;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 1em;
  padding: 1em;

}

@media screen and (max-width: 950px) {

  .discographyGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

}

@media screen and (max-width: 660px) {

  .discographyGrid {
    display: flex;
    flex-direction: column;
  }

}
