@import '../variables';

.paintedHeadshot {
    border-radius: 50%;
    border-width: 10px;
    cursor: pointer;
    padding:1em;
    margin-bottom: 0.5em;
    margin-top: 2.5em;
    height: 250px;
    width: 250px;
    border: 10px solid $transparent-background;

    &:hover{
      animation: pulse 5s infinite;
      animation-timing-function: linear;
    }

    @keyframes pulse {
      0% { transform: scale(1); }
      50% { transform: scale(1.1);}
      100% { transform: scale(1); }

    }
}

@keyframes stretch {
    0% {
      background-color: #e3d1be;
      border-radius: 100%;
    }
    50% {
      background-color: #f29024;
    }
    100% {
      background-color: white;
    }
  }

@media screen and (min-width: 1025px) {
  .paintedHeadshot {
    height: 300px;
    width: 300px;
  }
}

@media screen and (min-width: 1400px) {
  .paintedHeadshot {
    height: 350px;
    width: 350px;
  }
}
