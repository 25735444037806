.pressContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1em;
  width: 90vw;
}

.pressItem {
  border: solid 1px black;
  border-radius: 6px;
  margin: 1em;
  padding: 1em;
}

@media screen and (max-width: 650px) {

  .pressContainer {
    display: flex;
    flex-direction: column;
  }

}
