@mixin mainLink() {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

@mixin buttonStyle() {
  align-items: center;
  border-radius: 8px;
  color: black;
  display: flex;
  font-size: 1.2em;
  height: 5vh;
  justify-content: center;
  margin: 0.3em;
  padding: 0.3em;
  width: 260px;
  &:hover {
    background-color: black;
    color: white;
    fill: white;
  }
}

@mixin iconStyle() {
  height: 40px;
  &:hover {
    cursor: pointer;
    fill: $dark-third-accent;
  }
}

@mixin iconText() {
  font-size: 11px;
}
