$main-text-color: #000;
$primary-background-color: #f3e4cf;
$heading-text-color: #fff;
$fourth-accent-color: #8e45c4;
$first-accent-color: #1479ba;
$second-accent-color: #d23881;
$third-accent-color: #ef9349;
$fifth-accent-color: #f9e2b4;
$sixth-accent-color: #219f86;
$dark-first-accent: #dbc69a;
$dark-second-accent:#000;
$dark-third-accent: #282235;
$dark-fourth-accent: #403c49;
$dark-fifth-accent: #08304a;
$light-background:#fff;
$transparent-background: rgba(255, 255, 255, 0.6);
