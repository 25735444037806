@import './assets/stylesheets/App';
@import './assets/stylesheets/variables';
@import './assets/stylesheets/reset';
@import './assets/stylesheets/extends';
@import './assets/stylesheets/application.scss';

body, html {
    background-color: $primary-background-color;
    background-image: url(./colorStreak.PNG);
    background-size: 100%;
    color: $main-text-color;
    display: flex;
    flex-direction: column;
    margin: 0;
    font-family: 'Cabin', Sans-Serif;
    font-size: 20px;
    padding-bottom: 8em;
    width: 100%;
    line-height: 1.5;
  }

.buttonHome {
  @include buttonStyle();
  background-color: $light-background;
  border: solid 1px #000;
  cursor: pointer;
}

.buttonOutsideLink {
  @include buttonStyle();
  background-color: $fourth-accent-color;
  cursor: pointer;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  color: $light-background;
  text-shadow: 3px 3px 5px black;
  font-size: 2em;
  margin: 0;
  padding: 0 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-bottom: solid 5px #000;
}

h2 {
  margin: 0.1em;
  height: 1.5em;
}

h3 {
  margin: 0.5em;
}

@media screen and (min-width:500px) {
  h1 {
    font-size: 3em;
  }
}

@media screen and (min-width: 600px) {

  .buttonOne, .buttonTwo {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  body, html {
    background-size: 85%;
    background-repeat: repeat-y;
    background-position: right 0%;
  }

  h1 {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1300px) {
  main {
    padding: 2em;
  }
}

@media screen and (min-width: 1600px) {
  body, html {
    background-size: 85%;
  }

  h1 {
    padding-left: 5%;
  }
}
